import { Link } from 'react-router-dom';
import styles from './Header.module.css';

export default function HeaderStatic() {

  return (
    <div className={'active'}>
      <div className={styles.Header}>
        <Link to="/about" className={styles.Link}>
          <div className={styles.NavLink}>
            <h1>About</h1>
          </div>
        </Link>
        <Link to="/" className={styles.Link}>
          <div className={styles.NavLink}>
            <h1>Cameron Jiang</h1>
          </div>
        </Link>
        <Link to="/projects" className={styles.Link}>
          <div className={styles.NavLink}>
            <h1>Projects</h1>
          </div>
        </Link>
      </div>
    </div>
  );
}