import styles from './StartArrow.module.css';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

export default function StartArrow() {

  return (
    <motion.div
      initial={{ opacity: 0, scale: 1 }}
      animate={{
        opacity: 1,
        transition: { duration: 1, delay: 2 }
      }}
      whileHover={{
        scale: 1.2,
        transition: { duration: 0.1 }
      }}
    >
      <div className={styles.StartArrow}>
        <div>
          <motion.div
            initial={{ opacity: 0 }}
            whileHover={{
              opacity: 1,
              transition: { duration: 0.5, delay: 0 }
            }}
            className={styles.Border}
          >
          </motion.div>
          <FontAwesomeIcon icon={faArrowDown} bounce />
        </div>
      </div>
    </motion.div>
  )
}