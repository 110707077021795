import { Link } from 'react-router-dom';
import React, { useState, useEffect, useCallback } from 'react';
import styles from './Header.module.css';
import { motion } from "framer-motion";

export default function Header() {
    const [show, setShow] = useState(false);
    const [lastScrollY, setLastScrollY] = useState(0);
    const [appeared, setAppeared] = useState(false);

    const controlNavbar = useCallback(() => {
        if (window.scrollY < lastScrollY) {
            setShow(true);
        } else {
            setShow(false);
        }

        if (window.scrollY === 0 && appeared) {
            setShow(true);
        }

        // remember current page location to use in the next move
        setLastScrollY(window.scrollY);
    }, [lastScrollY, appeared]);

    useEffect(() => {
        if (!appeared) {
            return;
        }

        window.addEventListener('scroll', controlNavbar);

        // cleanup function
        return () => {
            window.removeEventListener('scroll', controlNavbar);
        };
    }, [lastScrollY, controlNavbar, appeared]);


    return (
        <div className={`active ${(!show) && 'hidden'}`}>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 3, delay: 2 }}
                onAnimationStart={() => {
                    function onAnimation() {
                        setAppeared(true);
                        if (window.scrollY === 0) {
                            setShow(true);
                        }
                    }
                    setTimeout(onAnimation, 2000);
                }}
            >
                <div className={styles.Header}>
                    <Link to="/about" className={styles.Link}>
                        <div className={styles.NavLink}>
                            <h1>About</h1>
                        </div>
                    </Link>
                    <Link to="/" className={styles.Link}>
                        <div className={styles.NavLink}>
                            <h1>Cameron Jiang</h1>
                        </div>
                    </Link>
                    <Link to="/projects" className={styles.Link}>
                        <div className={styles.NavLink}>
                            <h1>Projects</h1>
                        </div>
                    </Link>
                </div>
            </motion.div>
        </div>
    );
}