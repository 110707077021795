import styles from './Projects.module.css';

export default function Projects() {
  return (
    <div className={styles.Projects}>
      <div className={styles.Header}>
        <h1>Projects</h1>
      </div>
      <div className={styles.MainDiv}>
        <p><u>
          I'm currently working on some personal projects and freelance work. Some of them include:
        </u></p>

        <p>
          <a href='https://pi-game.cameron-jiang.com'>Pi-Game</a>
          <br />
          Press space to jump and down-arrow to duck. Up-arrow activates a power-up, and pressing space mid-air allows you to double-jump
        </p>

        <p>
          <a href='https://maze-man.cameron-jiang.com'>Maze-Man</a>
          <br />
          Click to focus mouse and use WASD to move. Hold shift to sprint. Find the exit before the monsters get you.
        </p>

        <p>
          <a href='https://birthday-clicker.cameron-jiang.com'>Birthday-Clicker</a>
          <br />
          Click the cake to play!
        </p>

        <p>
          <a href='https://letter-recognition.cameron-jiang.com'>Letter-Recognition</a>
          <br />
          A CNN trained to recognize handwritten capital English letters
        </p>

        <p>
          For more information, please contact me at <a href="mailto:22camerjian@gmail.com">22camerjian@gmail.com</a>.
        </p>
      </div >
      <div className={styles.Bottom}>
      </div>
    </div >
  );
}